<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="5" lg="4" class="ma-auto">
        <v-card class="px-8 pt-6 pb-12" outlined>
          <v-card-title class="text-center">
            <router-link to="/"
          >
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="70.000000pt" height="100%" viewBox="250 0 500.000000 326.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,326.000000) scale(0.100000,-0.100000)"
fill="#5c2e91" stroke="none">
<path fill="#5c2e91" d="M3290 2260 l0 -170 25 0 25 0 0 170 0 170 -25 0 -25 0 0 -170z"/>
<path fill="#5c2e91" d="M3400 2260 c0 -94 3 -169 8 -167 120 66 282 161 282 166 0 4 -42 31
-92 59 -51 28 -117 65 -145 82 l-53 30 0 -170z"/>
<path fill="#5c2e91" d="M3257 2045 c-64 -16 -107 -51 -152 -123 -1 -1 -21 16 -44 38 -113
107 -292 82 -376 -54 -76 -124 -10 -302 131 -351 32 -11 38 -17 35 -37 -1 -13
-4 -57 -5 -99 -1 -68 2 -80 29 -125 40 -63 107 -106 179 -112 l54 -4 -15 -45
c-40 -117 26 -258 142 -303 44 -17 134 -15 180 4 75 32 134 107 149 188 6 33
-3 98 -20 141 -5 14 0 17 26 17 156 0 273 162 226 313 -13 44 -13 49 2 53 129
41 191 119 192 242 0 126 -93 225 -224 239 -64 7 -137 -22 -186 -72 l-33 -34
-40 45 c-21 24 -53 52 -71 61 -42 23 -127 31 -179 18z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M4483 1892 c-103 -37 -167 -96 -214 -197 -84 -179 -47 -402 86 -523
71 -65 145 -94 245 -96 93 -1 141 15 196 66 l43 39 6 -45 7 -46 59 0 59 0 0
220 0 220 -170 0 -170 0 0 -70 0 -70 90 0 90 0 -6 -27 c-34 -146 -222 -191
-329 -79 -115 119 -89 388 44 456 48 24 138 27 179 6 35 -18 68 -54 81 -88 10
-27 12 -28 95 -28 l84 0 -10 43 c-20 88 -76 157 -168 203 -73 37 -216 45 -297
16z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M5341 1670 c-24 -11 -59 -37 -78 -58 -19 -20 -34 -32 -33 -27 2 6 2
27 1 48 l-1 37 -75 0 -75 0 0 -290 0 -290 79 0 79 0 4 168 c3 146 6 172 25
207 27 52 62 70 135 70 l59 0 -3 75 -3 75 -35 3 c-19 1 -55 -7 -79 -18z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M5690 1684 c-80 -15 -145 -60 -171 -118 -33 -72 -30 -76 61 -76 66 0
80 3 80 16 0 8 13 29 29 45 25 24 37 29 81 29 41 0 56 -5 75 -25 14 -13 25
-33 25 -45 0 -28 -33 -58 -72 -64 -18 -3 -72 -12 -120 -20 -52 -8 -103 -23
-124 -37 -103 -63 -93 -231 16 -286 75 -38 202 -30 272 19 34 24 38 23 38 -7
0 -25 1 -25 86 -25 66 0 83 3 77 13 -4 6 -10 116 -13 244 -6 265 -7 268 -93
312 -37 18 -67 24 -135 27 -48 1 -98 0 -112 -2z m180 -346 c0 -49 -18 -103
-39 -122 -27 -24 -84 -36 -127 -26 -45 10 -52 17 -60 56 -10 52 25 80 126 103
47 10 88 19 93 20 4 0 7 -14 7 -31z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M6396 1680 c-16 -5 -46 -23 -68 -40 l-38 -31 0 31 0 30 -75 0 -75 0
0 -390 0 -390 80 0 80 0 0 135 0 134 25 -25 c88 -88 271 -66 344 40 80 116 79
314 -3 421 -56 73 -182 113 -270 85z m94 -128 c63 -35 94 -141 70 -241 -23
-97 -117 -140 -198 -91 -45 28 -65 79 -65 166 1 86 26 143 75 167 43 22 76 21
118 -1z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M7003 1681 c-49 -13 -119 -57 -152 -98 -98 -119 -81 -341 32 -438 59
-50 122 -68 223 -63 106 6 163 37 212 118 17 30 32 58 32 62 0 4 -31 8 -70 8
-57 0 -71 -3 -79 -19 -23 -43 -134 -66 -184 -39 -41 22 -67 64 -67 109 0 18 9
19 206 19 l207 0 -5 73 c-7 112 -63 203 -153 249 -44 23 -148 33 -202 19z
m150 -139 c21 -18 47 -64 47 -82 0 -6 -44 -10 -125 -10 -115 0 -125 1 -125 19
0 26 38 71 74 87 38 17 103 10 129 -14z"/>
</g>
</svg>
        </router-link>
          
          </v-card-title>
          <v-card-subtitle class="mb-5">로그인 </v-card-subtitle>
          <v-card-text>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <form
                @submit.prevent="handleSubmit(signin)"
                @reset.prevent="reset"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Email"
                  >
                  <!-- rules="required|email" -->
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    label="이메일"
                    outlined
                    hide-details
                  ></v-text-field>
                  <p class="ma-0">
                    <v-btn
                      text
                      small
                      class="pl-0 text-capitalize"
                      color="blue lighten-1"
                      href="true"
                      >이메일을 잊으셨나요?</v-btn
                    >
                  </p>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Password"
                  >
                  <!-- rules="required" -->
                  <v-text-field
                    v-model="password"
                    type="password"
                    :error-messages="errors"
                    label="Password"
                    outlined
                    hide-details

                  ></v-text-field>
                  <p class="ma-0">
                    <v-btn
                      text
                      small
                      class="pl-0 text-capitalize"
                      color="blue lighten-1"
                      href="true"
                      >비밀번호를 잊으셨나요?</v-btn
                    >
                  </p>
                </ValidationProvider>
                <div class="mt-6 d-flex justify-space-between">
                  <v-btn
                    text
                    small
                    class="pl-0 text-capitalize"
                    color="primary"
                    router
                    to="signup"
                    >계정 만들기</v-btn
                  >
                  <v-btn type="submit" class="primary" depressed>로그인</v-btn>
                </div>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SignIn',
  data: () => ({
    email: '',
    password: ''
  }),
  methods: {
    signin() {
      console.log('hello')
    }
  }
}
</script>

<style></style>
